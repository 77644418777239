<!--Use it to display info and error to user, use 
  this.emitter.emit("displayinfo", {object with data example: title: "Title to display"})-->

<script>
import { useUserStore } from '@/store/userStore';
export default {
  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
    props:{
        show: Boolean,
        title: String,
        text: String,
        route: String,
        textlink: String,
        error: Boolean,
        valid: Boolean,
    },
    methods:{
        close(){
            this.userStore.ModalInfoData.show = false;
        },
        changeRoute(){
          this.userStore.ModalInfoData.show = false;
            this.$router.push('/'+ this.userStore.ModalInfoData.route)
        }
    }
};
</script>

<template>
  <div v-if="show">
    <div class="modal">
      <div class="modal-content">
        <div class="modal-header" >
            <img src="../../assets/icons/valid.svg" v-if="valid" alt="icon"/>
            <img src="../../assets/icons/error.svg" v-if="error" alt="icon"/>
            <div class="modal-title" v-if="title" :class="[valid ? 'valid-modal' : '', error ? 'error-modal' : '']">{{title}}</div>
        </div>
        <p class="modal-p" v-if="text">
          {{text}}
        </p>
        <div class="button-container">
          <button class="modal-button" :class="[valid ? 'valid-modal' : '', error ? 'error-modal' : '']" @click="close">OK</button>
          <button class="modal-button" :class="[valid ? 'valid-modal' : '', error ? 'error-modal' : '']" @click="changeRoute" v-if="textlink && route">{{textlink}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.valid-modal{
    color:var(--livz-bluebright);
}
.error-modal{
    color:var(--livz-red);
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-header{
    display: flex;
    align-items: center;
    margin-bottom:15px;
}
.modal-title{
    text-transform: uppercase;
    margin-left:5px;
    font-family: 'PoppinsBold', sans-serif;
}
.modal-p {
  color: var(--livz-blackbg);
  font-family: 'PoppinsLight',sans-serif;
  text-align: left;
  line-height: 1.2rem;
}
.modal-content {
  background: #fff;
  padding: 15px 20px;
  border-radius: 20px;
  text-align: center;
  width:75%;
}
.button-container{
    display: flex;
    flex-direction:row-reverse;
    width:100%;
    justify-content: space-between;
    margin-top:15px;
}
.modal-button{
    border: none;
    background: none;
    font-family: 'PoppinsLight',sans-serif;
    font-size:12px;
}
</style>
