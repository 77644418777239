import { createApp } from 'vue'
import { createPinia } from 'pinia'
import mitt from 'mitt';
const emitter = mitt();
import "./base.css";
import App from './App.vue'
import router from "./router";
import './registerServiceWorker'
import * as ConfirmDialog from 'vuejs-confirm-dialog'

const pinia = createPinia()
const app = createApp(App)

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.window = window;
app.use(pinia)
app.use(router);
app.use(ConfirmDialog);
app.mount("#app");