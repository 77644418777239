<script>
import CameraItem from "@/components/CameraItem.vue";
import { CameraPreview } from '@capacitor-community/camera-preview';
import photo from "../assets/photolight.svg";
import video from "../assets/cameralight.svg";
import { createMediaInCollection } from "../api/httpservice";
import SpinnerItem from "@/components/elements/SpinnerItem.vue";
import { useEventStore } from "../store/eventStore";
import { useUserStore } from "../store/userStore";
import { Device } from '@capacitor/device';

export default {
  setup() {
    const eventStore = useEventStore();
    const userStore = useUserStore();
    return { eventStore, userStore };
  },
  data() {
    return {
      modeSelected: 'video',
      flashAvailable: false,
      uploadWorking: false,
      recording: false,
      previewUri: null,
      starttouch: null,
      flash: false,
      needPhotoValidation: false,
      waitingCapture: false,
      errorPhoto: false,
    };
  },
  components: {
    CameraItem,
    SpinnerItem
  },
  watch: {
    $route(to, from) {
      if (to.name === "camera") {
        this.checkFlash()
        console.log("camera view start")
      }
      if (from.name === "camera") {
        console.log("camera view stop")
        this.flash = false;
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.waitingCapture && this.needPhotoValidation) {
      return
    }
    next();
  },
  mounted: async function () {
    console.log("mounted view")
    await this.logDeviceInfo();
    if (!this.errorPhoto) {
      this.cameraStart = true;
      await this.checkFlash()
      if (!this.$route.params.eventId) {
        return this.$router.push("/");
      }
      this.eventStore.uploadFilesInCollection();
      if (this.userStore.eventList && this.userStore.eventList.length) {
        this.eventStore.loadEventData(this.$route.params.eventId);
      } else {
        await this.userStore.userEventList().then(() => {
          this.eventStore.loadEventData(this.$route.params.eventId);
        })
      }
    }
  },
  methods: {
    handleProgress(progress, tmpId) {
      this.eventStore.uploadingFiles.forEach((file) => {
        if (file.tmpId === tmpId) {
          file.progress = progress;
        }
      });
    },
    async handleSelected(e) {
      if (e.target.files) {
        for (const file of Object.values(e.target.files)) {
          let tmpId = "id" + new Date().getTime();
          this.eventStore.uploadingFiles.push({ progress: 0, file: file, tmpId: tmpId });

          try {
            const res = await createMediaInCollection(file, this.$route.params.eventId, this.handleProgress, tmpId);

            if (res.status === 404) {
              this.eventStore.uploadingFiles.forEach((file) => {
                if (file.tmpId === tmpId) {
                  file.error = "Une erreur est survenue. Veuillez réessayer ultérieurement";
                }
              });
              this.emitter.emit('displayinfo', {
                title: "Erreur lors de l'envoi",
                text: "Une erreur est survenue lors de l'envoi du fichier. Veuillez réessayer ultérieurement.",
                error: true,
              });
            } else {
              let id = this.eventStore.uploadingFiles.findIndex((file) => file.tmpId === tmpId);
              if (id !== -1) {
                this.eventStore.uploadingFiles.splice(id, 1);
              }
            }
          } catch (error) {
            console.error('Error uploading file:', error);
            this.emitter.emit('displayinfo', {
              title: "Erreur lors de l'envoi",
              text: "Une erreur est survenue lors de l'envoi du fichier. Veuillez réessayer ultérieurement.",
              error: true,
            });
          }
        }
      }
    },
    flipCamera() {
      this.checkFlash().then(() => {
        if (this.needPhotoValidation) {
          return;
        }
        this.$refs.camera.flipCamera();
      })
    },
    startRecord() {
      this.recording = true;
      this.$refs.camera.startRecord();
    },
    stopRecord() {
      this.recording = false;
      this.$refs.camera.stopRecord();
    },
    captureImage() {
      if (this.needPhotoValidation) {
        return;
      }
      this.needPhotoValidation = true;
      this.waitingCapture = true;
      this.$refs.camera.captureImage().then(() => {
        this.needPhotoValidation = false;
        this.waitingCapture = false;
      })
    },
    getURL: function () {
      switch (this.modeSelected) {
        case "photo":
          return photo;
        case "video":
          return video;
        default:
          break;
      }
    },
    switchMode() {
      if (this.modeSelected === 'photo') {
        this.modeSelected = 'video'
      } else {
        this.modeSelected = 'photo'
      }
    },
    handleTouchStart(e) {
      this.starttouch = e.touches[0].clientX;
    },
    handleTouchMove(e) {
      if (Math.abs(e.touches[0].clientX - this.starttouch) < 180) {
        return;
      }
    },
    handleTouchEnd(event) {
      this.starttouch = null;
    },
    toggleFlash() {
      if (this.flash) {
        this.$refs.camera.closeFlash();
      } else {
        this.$refs.camera.enableFlash();
      }
      this.flash = !this.flash;
    },
    async checkFlash() {
      const flashModes = await CameraPreview.getSupportedFlashModes();
      const supportedFlashModes = flashModes.result;
      console.log("flashmodes", supportedFlashModes)
      if (supportedFlashModes.length > 0) {
        this.flashAvailable = true;
        console.log(this.flashAvailable)
      } else {
        this.flashAvailable = false;
        console.log(this.flashAvailable)
      }
    },
    async logDeviceInfo() {
      const info = await Device.getInfo();

      console.log("device info: ", info);
      if (info.name.includes("iPad"))
        this.errorPhoto = true;
    }
  },
};
</script>

<template id="camera-template">
  <div>
    <div v-if="errorPhoto" class="camera-error">
      La caméra n'est pas disponible sur votre appareil.
    </div>
    <div class="page-content" @touchstart.passive="handleTouchStart" @touchmove.passive="handleTouchMove"
      @touchend.passive="handleTouchEnd">
      <RouterLink :to="'/collection/' + this.$route.params.eventId">
      <div class="return-button" :class="{ disablebutton: needPhotoValidation }" v-if="!eventStore.captureStarted">
        <img src="../assets/icons/back-arrow.svg" alt="return" class="icon" />
      </div>
    </RouterLink>
      <div @click="toggleFlash" class="flip-button" :class="{ disablebutton: needPhotoValidation }"
        v-if="!eventStore.captureStarted && flashAvailable">
        <img v-if="flash" src="../assets/flashon.svg" alt="flashon" class="icon" />
        <img v-if="!flash" src="../assets/flashoff.svg" alt="flashoff" class="icon" />
      </div>
      <transition name="fadeblur">
        <div class="blurFilter" v-if="eventStore.captureStarted"></div>
      </transition>
      <div v-if="eventStore.captureStarted" class="waitingMessage">Prise de photo, ne bougez plus...</div>
      <SpinnerItem v-if="eventStore.captureStarted" class="waitingSpinner" />
      <div class="icon-container">
        <div v-if="!eventStore.captureStarted">
          <label id="fileButton" class="" for="fileInputSelect" :class="{ disablebutton: needPhotoValidation }">
            <img src="../assets/icons/media.svg" class="icon invert" alt="media" />
          </label>
          <input type="file" name="file" id="fileInputSelect" class="hidden-input" @change="handleSelected"
            multiple="true" ref="file" accept="image/*" />
        </div>
        <div @click="startRecord" v-if="modeSelected === 'photo' && !recording" class="start-record-button"></div>
        <div @click="stopRecord" v-if="modeSelected === 'photo' && recording" class="stop-record-button"></div>
        <div @click="captureImage" v-if="modeSelected === 'video' && !eventStore.captureStarted"
          class="take-photo-button">
          <img src="../assets/icons/capture.svg" alt="capture" />
        </div>
        <div class="switch-button" v-if="!eventStore.captureStarted">
          <img src="../assets/icons/swap.svg" alt="flip-camera" class="icon" @click="flipCamera" />
        </div>
      </div>
      <CameraItem ref="camera" :route="{ $route }" class="camera-display" />
    </div>
  </div>
</template>

<style scoped>
.blurFilter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  background-color: #28374a49;
  backdrop-filter: blur(10px);
  opacity: 1;
}

.fadeblur-enter-active,
.fadeblur-leave-active {
  transition: opacity 0.2s;
}

.fadeblur-enter,
.fadeblur-leave-to {
  opacity: 0;
}

.waitingMessage {
  position: absolute;
  width: 60%;
  left: 20%;
  right: 20%;
  top: 20%;
  text-align: center;
  font-size: 1.5rem;
  color: var(--livz-white);
  text-shadow: 1px 1px 3px var(--livz-blackbg);
  z-index: 9;
}

.waitingSpinner {
  z-index: 10;
}

.disablebutton {
  pointer-events: none;
}

.flip-button {
  position: fixed;
  top: 15px;
  right: 15px;
}

.return-button {
  position: fixed;
  top: 15px;
  left: 15px;
}

.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  z-index: 10;
}

.icon-container {
  position: absolute;
  bottom: 50px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-content {
  position: relative;
  height: 100%;
}

.take-photo-button {
  height: 70px;
  width: 70px;
  border-radius: 100px;
  margin: 0 75px;
  filter: drop-shadow(1px 1px 2px rgb(0 0 0 / 0.2));
}

.start-record-button {
  box-sizing: border-box;
  height: 50px;
  width: 50px;
  border-radius: 100px;
  margin: 0 50px;
  border: 15px solid white;
  background-color: red;
  filter: drop-shadow(1px 1px 2px rgb(0 0 0 / 0.2));
}

.stop-record-button {
  box-sizing: border-box;
  height: 50px;
  width: 50px;
  border-radius: 100px;
  margin: 0 75px;
  border: 5px solid white;
  background-color: red;
  filter: drop-shadow(1px 1px 2px rgb(0 0 0 / 0.2));
}

.icon {
  width: 30px;
  height: 30px;
  filter: drop-shadow(1px 1px 2px rgb(0 0 0 / 0.2));
}

.camera-display {
  pointer-events: none;
}

#camera-template {
  background-color: transparent !important;
}

.camera-error {
  background-color: var(--livz-blackbg);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  color: white;
}
</style>
