import { defineStore } from "pinia";
import { serverUrls } from "../api/constants";
import { callAPI } from "../api/httpservice";
import { useEventStore } from './eventStore.js'
export const useUserStore = defineStore({
  id: "userStore",
  state: () => ({
    token: "",
    userId: "",
    role: "",
    username: "",
    usermail: "",
    confirmedEmail: false,
    profilImageUrl: "",
    camera : {
      status : "stopped",
      activePromise : null
    },
    selfieUrl: "",
    firstConnection: false,
    mediaCount: 0,
    imageCount: 0,
    videoCount: 0,
    favoriteCount: 0,
    eventList: [],
    lastEvent: "",
    changeInfos: {
      newname: "",
      newmail: "",
      aiMe: false,
      aiAdmin: false,
      aiAll: false,
    },
    eventInfoToUpdate: false,
    newEvent:{
      name:"",
      description:"",
      place:"",
      dateStart:"",
      timeStart:"",
      dateEnd:"",
      timeEnd:"",
      cover:null,
      link:"",
      competitionLink:""
    },
    aiChoices: {
      aiForMe: false,
      aiForAdmin: false,
      aiForAll: false,
    },
    ModalInfoData: {
      show: false,
      title: "",
      text: "",
      route: "",
      textlink: "",
      error: false,
      valid: false,
    }
  }),
  getters: {},
  actions: {
    async setToken(token, notReloadData) {
      localStorage.setItem("token", token);
      this.token = token;
      if(!notReloadData){
      await this.getUserData();
      await this.userEventList();
      }
    },
    async getToken() {
      if (this.token) {
        return this.token;
      } else if (localStorage.getItem("token")) {
        return localStorage.getItem("token");
      }
    },
    disconnect() {
      this.token = "";
      this.userId = "";
      this.role = "";
      this.username = "";
      this.usermail = "";
      this.confirmedEmail = false;
      this.profilImageUrl = "";
      this.selfieUrl = "";
      this.mediaCount = 0;
      this.imageCount = 0;
      this.videoCount = 0;
      this.favoriteCount = 0;
      this.eventList = [];
      this.lastEvent = "";
      this.changeInfos = {
        newname: "",
        newmail: "",
        aiMe: false,
        aiAdmin: false,
        aiAll: false,
      };
      this.aiChoices = {
        aiForMe: false,
        aiForAdmin: false,
        aiForAll: false,
      };
      localStorage.removeItem("token");
      localStorage.removeItem("lastEventVisited");
      const eventStore = useEventStore();
      eventStore.removeEventData()
    },
    async signIn(email, password, username) {
      return await callAPI("POST", serverUrls.authUrl + "/auth/signup", {
        email,
        username,
        password,
      })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async logIn(email, password) {
      return await callAPI("POST", serverUrls.authUrl + "/auth/login", {
        email,
        password,
      })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async signWithGoogle(email, username) {
      return await callAPI("POST", serverUrls.authUrl + "/auth/google", {
        email,
        username,
      })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async signWithApple(email, familyName,givenName,user ) {
      console.log(email,
        familyName,
        givenName,
        user)
      return await callAPI("POST", serverUrls.authUrl + "/auth/apple", {
        email,
        familyName,
        givenName,
        user,
      })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async signWithFacebook() {
      return await callAPI("GET", serverUrls.authUrl + "/auth/facebook", null)
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },

    async confirmMail(token) {
      return await callAPI(
        "GET",
        serverUrls.authUrl + "/auth/confirm-email?token=" + token,
        null
      )
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async retrievePassword(email) {
      return await callAPI(
        "GET",
        serverUrls.authUrl + "/auth/reset-password?email=" + email,
        null
      )
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async resetPassword(token, password) {
      return await callAPI(
        "POST",
        serverUrls.authUrl +
          "/auth/reset-password?token=" +
          token +
          "&password=" +
          password,
        null
      )
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async updatePassword(oldPassword, newPassword) {
      return await callAPI(
        "PATCH",
        serverUrls.authUrl +
          "/auth/update-password",
        {oldPassword, newPassword}
      )
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async deleteAccount(userId) {
      return await callAPI("DELETE", serverUrls.authUrl + "/auth/account?userId="+userId , null)
        .then((res) => {
          return res
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    // USER METHODS
    async getUsers() {
      return await callAPI("GET", serverUrls.authUrl + "/users/all-users", null)
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async getUserData() {
      return await callAPI("GET", serverUrls.authUrl + "/users", null)
        .then((response) => {
          this.userId = response.userId;
          this.role = response.role;
          this.username = response.username;
          this.usermail = response.email;
          this.confirmedEmail = response.confirmedEmail;
          this.mediaCount = response.mediaCount;
          this.selfieUrl = response.selfieUrl;
          this.changeInfos.newname = response.username;
          this.changeInfos.newmail = response.email;
          this.changeInfos.aiMe = response.authorizeAiSearch ? response.authorizeAiSearch : false;
          this.changeInfos.aiAdmin = response.authorizeOrgaSearch ? response.authorizeOrgaSearch : false;
          this.changeInfos.aiAll = response.authorizeAllSearch ? response.authorizeAllSearch : false;
          this.aiChoices.aiForMe = response.authorizeAiSearch ? response.authorizeAiSearch : false;
          this.aiChoices.aiForAdmin = response.authorizeOrgaSearch ? response.authorizeOrgaSearch : false;
          this.aiChoices.aiForAll = response.authorizeAllSearch ? response.authorizeAllSearch : false;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async changeAiPermissions(){
      return await callAPI("PUT", serverUrls.authUrl + "/users/ai-permissions", {
        authorizeAiSearch:this.aiChoices.aiForMe,
        authorizeOrgaSearch:this.aiChoices.aiForAdmin,
        authorizeAllSearch:this.aiChoices.aiForAll,
      })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async updateAccount() {
      let username = this.changeInfos.newname;
      let email = this.changeInfos.newmail;
      return await callAPI("PATCH", serverUrls.authUrl + "/users", {
        username,
        email,
      })
        .then((response) => {
          this.username = this.changeInfos.newname;
          this.usermail = this.changeInfos.newmail;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async userEventList() {
      return await callAPI(
        "GET",
        serverUrls.authUrl + "/users/event-list",
        null
      )
        .then((response) => {
          this.eventList = response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async getEventList(eventId) {
      return await callAPI(
        "GET",
        serverUrls.authUrl + "/users/event-participants?eventId=" + eventId,
        null
      )
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    // ADMIN METHODS needs admin token
    async changeMailValidation(userId) {
      return await callAPI(
        "PATCH",
        serverUrls.authUrl + "/admin/toggle-mail-validation",
        { userId }
      )
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async createNewUser(newUser) {
      return await callAPI("POST", serverUrls.authUrl + "/admin/user", {
        username: newUser.username,
        email: newUser.email,
        confirmedEmail: newUser.confirmedEmail,
        password: newUser.password,
      })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async editUserData(editUser) {
      return await callAPI("PATCH", serverUrls.authUrl + "/admin/user", {
        userId: editUser._id,
        username: editUser.username,
        email: editUser.email,
        confirmedEmail: editUser.confirmedEmail,
      })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    // PERMISSIONS FOR EVENT METHODS
    async joinEvent(eventId) {
      return await callAPI("PATCH", serverUrls.authUrl + "/permissions/join", {
        eventId,
      })
        .then((response) => {
          this.setToken(response.token);
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async leaveEvent(eventId) {
      return await callAPI("PATCH", serverUrls.authUrl + "/permissions/leave", {
        eventId,
      })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async askforOrganizerRole(message, company, phone) {
      return await callAPI(
        "POST",
        serverUrls.authUrl + "/permissions/ask-for-organizer",
        { message, company, phone }
      )
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async attributeOrganizer(userId) {
      return await callAPI(
        "PATCH",
        serverUrls.authUrl + "/permissions/organizer",
        { userId }
      )
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async attributeModerator(eventId, userId) {
      return await callAPI(
        "PATCH",
        serverUrls.authUrl + "/permissions/moderator",
        { eventId, userId }
      )
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async attributeAdmin(userId) {
      return await callAPI("PATCH", serverUrls.authUrl + "/permissions/admin", {
        userId,
      })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async removeRoles(userId) {
      return await callAPI("DELETE", serverUrls.authUrl + "/permissions", {
        userId,
      })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async removeSelfie() {
      return await callAPI("DELETE", serverUrls.mediaUrl + "/selfie", null)
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async askDeleteAccount(email) {
      return await callAPI("DELETE", serverUrls.authUrl + "/users/account?email=" + email, null)
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
  },
});
