<script>
import { CameraPreview } from "@capacitor-community/camera-preview";
import { Filesystem, Directory } from "@capacitor/filesystem";
import { Camera, CameraDirection, CameraResultType, CameraSource } from '@capacitor/camera';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { useUserStore } from "@/store/userStore";
import { useEventStore } from "@/store/eventStore";

export default {
  setup() {
    const userStore = useUserStore();
    const eventStore = useEventStore();
    return { eventStore, userStore };
  },
  data() {
    return {
      cameraPosition: "rear",
      cameraStarted: false,
      flash: false,
      imagePreview: null,
      imageFileName: null,
      eventListener: null,
      eventId: null,
      captureStarted: false,
      previewImagePlaceholder: null,
    };
  },
  props: {
    route: Object
  },
  watch: {
    async route(to, from) {
      console.log("tofrom", to, from)
      if (from.$route.name !== "camera" && to.$route.name === "camera" && this.eventId === to.$route.params.eventId) {
        this.active = true
        console.log("arriving", to.$route.params.eventId)
        this.stopStart();
        if (!this.eventListener) {
          this.eventListener = screen.orientation.addEventListener("change", this.changeCameraOrientation);
        }
      }
      if (from.$route.name === "camera" && to.$route.name !== "camera" || this.eventId !== to.$route.params.eventId) {
        if (!this.active) {
          return;
        }
        console.log("exiting", to.$route.params.eventId)
        if (this.userStore.camera.status === "started") {
          await this.stopCamera()
          this.active = false
        }
        else {
          if (this.userStore.camera.status === "starting") {
            await this.userStore.camera.activePromise.then(async () => {
              await this.stopCamera()
              this.active = false
            })
          } else {
            this.active = false
          }
        }

        if (this.eventListener) {
          screen.orientation.removeEventListener("change", this.eventListener);
          this.eventListener = null;
        }
      }
    }
  },
  updated() {
  },
  mounted: function () {
    this.eventId = this.route.$route.params.eventId;
    this.active = true
    console.log("mounted item")
    this.stopStart();
    if (!this.eventListener) {
      this.eventListener = screen.orientation.addEventListener("change", this.changeCameraOrientation);
    }
  },
  unmounted: async function () {
    console.log("unmounted item")
  },
  methods: {
    stopStart(width, height) {
      if (!this.active) {
        return;
      }
      console.log("stopStart", this.userStore.camera.status, width, height)
      return new Promise((resolve, reject) => {
        switch (this.userStore.camera.status) {
          case "starting":
            this.userStore.camera.activePromise.then(() => {
              this.stopCamera();
              this.userStore.camera.activePromise.then(() => {
                this.startCamera(width, height);
                resolve();
              });
            });
            break;
          case "started":
            this.stopCamera();
            this.userStore.camera.activePromise.then(() => {
              this.startCamera(width, height);
              resolve();
            });
            break;
          case "stopping":
            this.userStore.camera.activePromise.then(() => {
              this.startCamera(width, height);
              resolve();
            });
            break;
          case "stopped":
            this.startCamera(width, height)
            resolve();
            break;
        }
      })
    },
    async changeCameraOrientation() {
      if (this.$route.name === 'camera') {
        console.log("change orientation")
        this.stopStart();
      } else {
        ScreenOrientation.lock({
          orientation: 'portrait',
        });
        screen.orientation.removeEventListener("change", this.changeCameraOrientation);
      }
    },
    async startCamera(width, height) {
      if (!this.active) {
        return;
      }
      console.log("startCamera", this.userStore.camera.status, width, height)
      let widthcenter = -540 + window.innerWidth / 2;
      let heightcenter = -960 + window.innerHeight / 2;
      const cameraPreviewOptions = {
        parent: "cameraPreview",
        position: this.cameraPosition,
        width: width ? width : window.innerWidth,
        height: height ? height : window.innerHeight,
        x: width ? widthcenter : 0,
        y: height ? heightcenter : 0,
        toBack: true,
        storeToFile: true,
        disableExifHeaderStripping: false,
      };
      this.userStore.camera.activePromise = new Promise((resolve, reject) => {
        console.log("changing status to starting")
        this.userStore.camera.status = "starting"
        try {

          CameraPreview.start(cameraPreviewOptions).then(() => {
            console.log("changing status to started")
            this.userStore.camera.status = "started"


            resolve("OK")
          });
        } catch (error) {
          reject(error)
        }
      });
    },
    async stopCamera() {
      if (!this.active) {
        return;
      }
      console.log("stopCamera", this.userStore.camera.status)
      this.userStore.camera.activePromise = new Promise((resolve, reject) => {
        this.userStore.camera.status = "stopping"
        console.log("changing status to stopping")
        try {
          CameraPreview.stop().then(() => {
            this.userStore.camera.status = "stopped"
            console.log("changing status to stop")
            resolve("OK")
          });
        } catch (error) {
          reject(error)
        }
      });
    },
    async flipCamera() {
      console.log("flipCamera", this.userStore.camera.status)
      if (this.cameraPosition === "rear") {
        this.cameraPosition = "front";
      } else {
        this.cameraPosition = "rear";
      }
      this.stopStart()
    },
    async captureImage() {
      this.eventStore.captureStarted = true;

      const cameraSampleOptions = {
        quality: 50
      };
      const result = await CameraPreview.captureSample(cameraSampleOptions);
      this.previewImagePlaceholder = `data:image/jpeg;base64,${result.value}`;


      console.log("captureImage", this.userStore.camera.status)
      this.stopStart(1080, 1920).then(() => {
        this.userStore.camera.activePromise.then(async () => {
          const cameraPreviewPictureOptions = {
            quality: 100,
            width: 1080,
            height: 1920,
          };
          if (this.flash && this.cameraPosition === "rear") {
            this.enableFlash()
          }

          await new Promise(resolve => { setTimeout(resolve, 1000) });

          const result = await CameraPreview.capture(cameraPreviewPictureOptions);
          const fileName = result.value.substring(
            result.value.lastIndexOf("/") + 1
          );
          const file = await Filesystem.readFile({
            path: fileName,
            directory: Directory.Cache,
          });
          this.imageFileName = fileName;
          this.previewImagePlaceholder = null;
          this.imagePreview = `data:image/jpeg;base64,${file.data}`;
          this.eventStore.captureStarted = false;
          this.stopStart();
        })
      })
    },

    async testTakePhoto() {
      const image = await Camera.getPhoto({
        quality: 100,
        allowEditing: false,
        resultType: CameraResultType.Base64,
        saveToGallery: false,
        width: 1080,
        height: 1920,
        correctOrientation: true,
        source: CameraSource.Camera,
        direction: CameraDirection.Front,
      });
      console.log(image.base64String)
      this.imagePreview = `data:image/jpeg;base64,${image.base64String}`;
    },

    async confirmImage() {
      let fileName = "upload/" + new Date().getTime() + ".jpeg";
      Filesystem.copy({
        from: this.imageFileName,
        directory: Directory.Cache,
        to: fileName,
        toDirectory: Directory.Data,
      }).then(() => {
        const deleteOptions = {
          path: this.imageFileName,
          directory: Directory.Cache,
        };
        Filesystem.deleteFile(deleteOptions);
        this.imageFileName = null;
        this.imagePreview = null;
        this.eventStore.uploadFilesInCollection();
      });
    },
    cancelFile() {
      this.imageFileName = null;
      this.imagePreview = null;
    },
    enableFlash() {
      this.flash = true;
      const cameraPreviewFlashMode = { flashMode: 'on' };
      CameraPreview.setFlashMode(cameraPreviewFlashMode);
    },
    closeFlash() {
      this.flash = false;
      const cameraPreviewFlashMode = { flashMode: 'off' };
      CameraPreview.setFlashMode(cameraPreviewFlashMode);
    },
  },
};
</script>

<template>
  <div>
    <div class="previewPlaceholder" v-if="previewImagePlaceholder">
      <img class="previewPlaceholderImg" :src="previewImagePlaceholder" alt="preview" />
    </div>
    <div class="imagePreview" v-if="imagePreview">
      <img class="icon-preview-confirm" src="../assets/done.svg" alt="confirmImage" @click="confirmImage" />
      <img class="icon-preview-cancel" src="../assets/close.svg" alt="cancelImage" @click="cancelFile" />
      <img class="preview" :src="imagePreview" alt="preview" />
    </div>
    <div id="cameraPreview"></div>
    <div class="background-camera" v-if="this.userStore.camera.status !== 'started'"></div>
  </div>
</template>

<style scoped>
#cameraPreview {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.background-camera {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--livz-blackbg);
  z-index: -1;
}

.imagePreview {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background-color: var(--livz-blackbg);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.previewPlaceholder {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--livz-blackbg);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0px;
}

.previewPlaceholderImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preview {
  width: 90%;
  max-height: 75%;
  object-fit: contain;
}


.icon-preview-confirm {
  pointer-events: initial;
  position: fixed;
  bottom: 5%;
  right: 20%;
  filter: invert(1);
}

.icon-preview-cancel {
  pointer-events: initial;
  position: fixed;
  bottom: 5%;
  left: 20%;
  filter: invert(1);
}
</style>
