<script>
export default {
  props:{
    title: String,
    place: String,
    dateStart: String,
    dateEnd: String,
    isOpen: Boolean,
    backgroundUrl: String
  },
  methods:{
    getURL() {
      const imageUrl = new URL(this.backgroundUrl, import.meta.url).href;
      return imageUrl;
    },
  },
}
</script>

<template>
  <div class="event-box">
    <img class="box-img" :src="getURL()" alt="box-img">
    <div class="box-left">
        <div class="box-title">{{this.title}}</div>
        <div class="box-info">
        <div class="box-date"><img class="box-icon" src="../../assets/icons/watch.svg" alt="time">{{this.dateStart}} - {{this.dateEnd}}</div>
        <div class="box-place"><img class="box-icon" src="../../assets/icons/geolocalisation.svg" alt="place">{{this.place}}</div>
      </div>
    </div>
    <div class="box-open" :style="{backgroundColor: this.isOpen ? 'var(--livz-green)' : 'var(--livz-yellow)'}">
        <div class="box-is-open">{{ this.isOpen ? 'Ouvert' : 'Fermé'}}</div>
    </div>
  </div>
</template>

<style scoped>
  .event-box{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    width:90%;
    margin:1rem 5%;
    color:var(--livz-white);
    font-weight: bold;
    border-bottom:1px solid var(--livz-blueinactive);
  }
  .box-img{
    width:100%;
    height:110px;
    border-radius: 20px;
    object-fit: cover;
  }
  .box-left{
    text-align: left;
    width:95%;
    margin:0 auto;
  }
  .box-info{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin:.5rem 0;
  }
  .box-title{
    font-size:12px;
    font-family: 'PoppinsBold',sans-serif;
    color:var(--livz-grey);
    margin-top:10px;
  }
  .box-place{
    margin:0.3rem 0;
    width:fit-content;
    font-size:10px;
    font-family: 'PoppinsLight',sans-serif;
    color:var(--livz-grey);
  }
  .box-date{
    width:fit-content;
    margin-right: 10px;
    font-size:10px;
    font-family: 'PoppinsLight',sans-serif;
    color:var(--livz-grey);
  }
  .box-icon{
    width:12px;
    height:12px;
    margin-right: 5px;
  }
  .box-open{
    position: absolute;
    top:20px;
    padding:2px 15px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .box-is-open{
    font-size:0.8rem;
    padding:.2rem;
    font-family: 'PoppinsLight',sans-serif;
    font-style: normal;
  }
</style>