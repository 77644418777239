<script>
import { useUserStore } from '@/store/userStore';
import { useEventStore } from '@/store/eventStore';
import ButtonItem from "../components/elements/ButtonItem.vue";
import EventBox from "../components/events/EventBox.vue"
export default {
  setup() {
    const userStore = useUserStore();
    const eventStore = useEventStore();
    return { userStore, eventStore };
  },
  watch: {
    '$route'(to, from) {
      if (to.name === 'home') {
        this.eventStore.eventId = "";
        this.eventStore.eventName = "";
        this.eventStore.eventIsOpen = false;
        this.eventStore.eventDescription = "";
        this.eventStore.eventPlace = "";
        this.eventStore.eventBackgroundUrl = "";
        this.eventStore.eventDateStart = "";
        this.eventStore.eventDateEnd = "";
        this.eventStore.eventLink = "";
        this.eventStore.eventOrgaLink = "";
        this.eventStore.eventCompetitionLink = "";
        this.eventStore.eventOrgaName = "";
        this.eventStore.eventUserList = "";
        this.eventStore.imageCount = 0;
        this.eventStore.videoCount = 0;
        this.eventStore.usersCount = 0;
        this.eventStore.eventUserRole = "";
        localStorage.removeItem('lastEventVisited');
        this.userStore.newEvent.name = "";
        this.userStore.newEvent.description = "";
        this.userStore.newEvent.competitionLink = "";
        this.userStore.newEvent.place = "";
        this.userStore.newEvent.link = "";
        this.userStore.newEvent.dateStart = "";
        this.userStore.newEvent.dateEnd = "";
        this.userStore.newEvent.timeStart = "";
        this.userStore.newEvent.timeEnd = "";
        this.userStore.newEvent.cover = null;
      }
    }
  },
  computed: {
    showCreateEvent() {
      return this.userStore.role === 'admin' || this.userStore.role === 'organizer';
    },
    ongoingEvents() {
      const now = new Date();
      now.setHours(0,0,0,0)
      return this.userStore.eventList.filter(event => new Date(event.eventData.dateStart).setHours(0,0,0,0) <= now && new Date(event.eventData.dateEnd).setHours(0,0,0,0) >= now);
    },
    upcomingEvents() {
      const now = new Date();
      now.setHours(0,0,0,0)
      return this.userStore.eventList.filter(event => new Date(event.eventData.dateStart).setHours(0,0,0,0) > now && new Date(event.eventData.dateEnd).setHours(0,0,0,0) > now);
    },
    pastEvents() {
      const now = new Date();
      now.setHours(0,0,0,0)
      return this.userStore.eventList.filter(event => new Date(event.eventData.dateStart).setHours(0,0,0,0) < now && new Date(event.eventData.dateEnd).setHours(0,0,0,0) < now);
    },
  },
  components: {
    ButtonItem,
    EventBox,
  },
  methods: {
    redirect(eventId) {
      let route = '/collection/' + eventId;
      localStorage.setItem('lastEventVisited', eventId);
      this.$router.push(route)
    },
  }
}
</script>

<template>
  <div class="pageContent">
    <div class="app-logo">
      <img class="app-logo-image" src="../assets/logo.png" alt="Livz">
    </div>
    <RouterLink to="/form-event-name" v-if="showCreateEvent">
      <ButtonItem class="top-button">
        <template #title>Créer une page évènement</template>
      </ButtonItem>
    </RouterLink>
    <RouterLink to="/organisator-form" v-if="!showCreateEvent">
      <ButtonItem class="top-button">
        <template #title>Demander un compte organisateur</template>
      </ButtonItem>
    </RouterLink>
    <div class="separator"></div>
    <p class="app-title">Mes évènements</p>
    <RouterLink to="/event-connection">
      <ButtonItem id="event-creation-button">
        <template #title>Rejoindre un évènement</template>
      </ButtonItem>
    </RouterLink>
    <p class="app-no-event" v-if="!userStore.eventList.length">Il n'y a pas d'évènements</p>

    <div v-if="ongoingEvents.length > 0">
      <p class="app-subtitle">Évènements en cours</p>
      <div class="event-list">
        <div class="no-router-style" v-for="(event, index) in ongoingEvents" :key="index"
          @click="redirect(event.eventData.eventCode)">
          <EventBox :title="event.eventData.name" :place="event.eventData.place" :dateStart="event.eventData.dateStart"
            :dateEnd="event.eventData.dateEnd" :isOpen="event.eventData.ongoing" :imageCount="event.eventData.imageCount"
            :videoCount="event.eventData.videoCount" :usersCount="event.eventData.usersCount"
            :backgroundUrl="event.eventData.coverUrl" />
        </div>
      </div>
    </div>

    <div v-if="upcomingEvents.length > 0">
      <p class="app-subtitle">Évènements à venir</p>
      <div class="event-list">
        <div class="no-router-style" v-for="(event, index) in upcomingEvents" :key="index"
          @click="redirect(event.eventData.eventCode)">
          <EventBox :title="event.eventData.name" :place="event.eventData.place" :dateStart="event.eventData.dateStart"
            :dateEnd="event.eventData.dateEnd" :isOpen="event.eventData.ongoing" :imageCount="event.eventData.imageCount"
            :videoCount="event.eventData.videoCount" :usersCount="event.eventData.usersCount"
            :backgroundUrl="event.eventData.coverUrl" />
        </div>
      </div>
    </div>

    <div v-if="pastEvents.length > 0">
      <p class="app-subtitle">Évènements passés</p>
      <div class="event-list">
        <div class="no-router-style" v-for="(event, index) in pastEvents" :key="index"
          @click="redirect(event.eventData.eventCode)">
          <EventBox :title="event.eventData.name" :place="event.eventData.place" :dateStart="event.eventData.dateStart"
            :dateEnd="event.eventData.dateEnd" :isOpen="event.eventData.ongoing" :imageCount="event.eventData.imageCount"
            :videoCount="event.eventData.videoCount" :usersCount="event.eventData.usersCount"
            :backgroundUrl="event.eventData.coverUrl" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.pageContent {
  position: relative;
  height: calc(100% - 50px);
  max-width: 500px;
  margin: 0 auto;
  overflow-y: auto;
  padding-bottom: 20px;
  text-align: center;
}

.no-router-style {
  text-decoration: none;
  color: initial;
}

.separator {
  width: 100vw;
  height: 1px;
  background-color: var(--livz-grey);
  margin: 1rem 0;
}

#event-creation-button {
  margin: 1rem auto;
  font-size: 13px;
}

.app-link-orga {
  text-align: center;
  margin: 0 0 2rem 0;
}

.app-link-text a {
  font-size: 0.8rem;
  font-weight: bold;
  width: fit-content;
  color: var(--livz-white);
  transition: color 0.2s ease-in-out;
  text-decoration: underline;
}

.app-title {
  text-align: center;
  text-transform: uppercase;
  font-size: .9rem;
  margin: 1.5rem 0 .5rem;
  font-family: Poppins, sans-serif;
  color: var(--livz-white);
}

.app-logo {
  display: flex;
  justify-content: center;
}

.app-logo-image {
  width: 80px;
  height: 80px;
  background-color: var(--livz-darkblue);
  border-radius: 50px;
}
.app-no-event{
  text-align: left;
  width:90%;
  margin:0 auto;
  text-transform: uppercase;
  font-size:12px;
}
.app-subtitle{
  text-align: left;
  width:90%;
  margin:2rem auto;
  text-transform: uppercase;
  font-size:13px;
  color: var(--livz-white);
}
.top-button{
  border:1px solid var(--livz-white);
  background-color: var(--livz-blackbg);
  font-size:13px;
}
</style>