<template>
  <button class="button-item">
    <slot name="title"></slot>
  </button>
</template>

<style>
.button-item {
  cursor: pointer;
  color: white;
  width: 85%;
  border-radius: 50px;
  border-style: hidden;
  font-size: 1.3rem;
  margin: 2rem auto 1rem;
  padding: 10px 30px;
  font-family: PoppinsBold, sans-serif;
  text-transform: uppercase;
  background: var(--livz-blue);
  transition: background 0.3s ease-in-out;
}
.button-item:hover {
  background: var(--livz-darkblue);
}
@media (max-width: 800px) {
  .button-item {
    font-size: 1rem;
  }
}
</style>
