import { validTokenAfterError, TokenAdminVerification } from "@/api/httpservice";
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { createRouter, createWebHistory } from "vue-router";
import { useUserStore } from '../store/userStore.js'
import CameraView from "../views/CameraView.vue";
import HomeView from "../views/HomeView.vue";
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
      meta: {
        showGeneralMenu: true,
      },
      beforeEnter: async (to, from) => {
        const res = await validTokenAfterError(true)
        if (res) {
          return true;
        } else {
          return { name: 'login' }
        }
      },
    }, ,
    {
      path: "/camera/:eventId",
      name: "camera",
      component: CameraView,
      beforeEnter: async (to, from) => {
        const res = await validTokenAfterError(true)
        if (res) {
          const userStore = useUserStore();
          if (!userStore.eventList.find((event) => event.eventData.eventCode === to.params.eventId)) {
            return { name: 'home' }
          } else {
            return true
          }
        } else {
          return { name: 'login' }
        }
      },
    },
    {
      path: "/cgu",
      name: "cgu",
      meta: {
        showGeneralMenu: true,
      },
      component: () => import("../views/CGUView.vue"),
    },

    {
      path: "/privacy",
      name: "privacy",
      meta: {
        showGeneralMenu: true,
      },
      component: () => import("../views/PrivacyPolicyView.vue"),
    },
    {
      path: "/collection/:eventId",
      name: "collection",
      meta: {
        showNavbar: true,
      },
      component: () => import("../views/CollectionView.vue"),
      beforeEnter: async (to, from) => {
        const eventId = to.params.eventId;
        const res = await validTokenAfterError(true)
        if (res && eventId) {
          const userStore = useUserStore();
          if (!userStore.eventList.find((event) => event.eventData.eventCode === to.params.eventId)) {
            return { name: 'home' }
          } else {
            return true
          }
        } else {
          return { name: 'login' }
        }
      },
    },
    {
      path: "/confirmation",
      name: "confirmation",
      component: () => import("../views/User/ConfirmationAccountView.vue"),
    },
    {
      path: "/connect",
      name: "connect",
      meta: {
        showGeneralMenu: true,
      },
      component: () => import("../views/User/ConnectView.vue"),
    },
    {
      path: "/delete-account",
      name: "deleteaccount",
      meta: {
        showGeneralMenu: true,
      },
      component: () => import("../views/User/DeleteAccountView.vue"),
      beforeEnter: async (to, from) => {
        const res = await validTokenAfterError(true)
        if (res) {
          return true
        } else {
          return { name: 'login' }
        }
      },
    },
    {
      path: "/delete-policy-rgpd",
      name: "deletepolicy",
      component: () => import("../views/User/DeletePolicy.vue"),
    },
    {
      path: "/event/:eventId",
      name: "eventLink",
      meta: {
        showGeneralMenu: true,
      },
      component: () => import("../views/EventLinkView.vue"),
    },
    {
      path: "/event-administration",
      name: "eventadministration",
      meta: {
        showGeneralMenu: true,
      },
      component: () => import("../views/EventAdministrationView.vue"),
      beforeEnter: async (to, from) => {
        if (await TokenAdminVerification()) {
          return true
        } else {
          return { name: 'login' }
        }
      },
    },
    {
      path: "/event-connection",
      name: "eventconnection",
      meta: {
        showGeneralMenu: true,
      },
      component: () => import("../views/EventConnectionView.vue"),
    },
    {
      path: "/qr-scanner",
      name: "qrscanner",
      component: () => import("../views/QRScannerView.vue"),
    },
    {
      path: "/event-creation",
      name: "eventcreation",
      meta: {
        showGeneralMenu: true,
      },
      component: () => import("../views/EventCreationView.vue"),
      beforeEnter: (to, from) => {
        const userStore = useUserStore();
        if (userStore.role === 'admin' || userStore.role === 'organizer') {
          return true
        } else {
          return { name: 'home' }
        }
      },
    },
    {
      path: "/event-modification/:eventId",
      name: "eventmodification",
      meta: {
        showGeneralMenu: true,
      },
      component: () => import("../views/EventModificationView.vue"),
      beforeEnter: (to, from) => {
        const userStore = useUserStore();
        if (userStore.role === 'admin' || userStore.role === 'organizer') {
          return true
        } else {
          return { name: 'home' }
        }
      },
    },
    {
      path: "/event-information",
      name: "eventinformation",
      meta: {
        showNavbar: true,
      },
      component: () => import("../views/EventInformationView.vue"),
      beforeEnter: async (to, from) => {
        const res = await validTokenAfterError(true)
        if (res) {
          return true;
        } else {
          return { name: 'login' }
        }
      },
    },
    {
      path: "/event-user-list/:eventId",
      name: "eventuserlist",
      meta: {
        showNavbar: true,
      },
      component: () => import("../views/EventUserListView.vue"),
    },
    {
      path: "/forgotpassword",
      name: "forgotpassword",
      component: () => import("../views/User/ForgotPasswordView.vue"),
    },
    {
      path: "/form-event-name",
      name: "formeventname",
      component: () => import("../views/Form/FormEventNameView.vue"),
      beforeEnter: (to, from) => {
        const userStore = useUserStore();
        if (userStore.role === 'admin' || userStore.role === 'organizer') {
          return true
        } else {
          return { name: 'home' }
        }
      },
    },
    {
      path: "/form-event-description",
      name: "formeventdescription",
      component: () => import("../views/Form/FormEventDescriptionView.vue"),
      beforeEnter: (to, from) => {
        const userStore = useUserStore();
        if (userStore.role === 'admin' || userStore.role === 'organizer') {
          return true
        } else {
          return { name: 'home' }
        }
      },
    },
    {
      path: "/form-event-place",
      name: "formeventplace",
      component: () => import("../views/Form/FormEventPlaceView.vue"),
      beforeEnter: (to, from) => {
        const userStore = useUserStore();
        if (userStore.role === 'admin' || userStore.role === 'organizer') {
          return true
        } else {
          return { name: 'home' }
        }
      },
    },
    {
      path: "/form-event-date-start",
      name: "formeventdatestart",
      component: () => import("../views/Form/FormEventDateStartView.vue"),
      beforeEnter: (to, from) => {
        const userStore = useUserStore();
        if (userStore.role === 'admin' || userStore.role === 'organizer') {
          return true
        } else {
          return { name: 'home' }
        }
      },
    },
    {
      path: "/form-event-date-end",
      name: "formeventdateend",
      component: () => import("../views/Form/FormEventDateEndView.vue"),
      beforeEnter: (to, from) => {
        const userStore = useUserStore();
        if (userStore.role === 'admin' || userStore.role === 'organizer') {
          return true
        } else {
          return { name: 'home' }
        }
      },
    },
    {
      path: "/form-event-cover",
      name: "formeventcover",
      component: () => import("../views/Form/FormEventCoverView.vue"),
      beforeEnter: (to, from) => {
        const userStore = useUserStore();
        if (userStore.role === 'admin' || userStore.role === 'organizer') {
          return true
        } else {
          return { name: 'home' }
        }
      },
    },
    {
      path: "/form-event-competition",
      name: "formeventcompetition",
      component: () => import("../views/Form/FormEventCompetitionView.vue"),
      beforeEnter: (to, from) => {
        const userStore = useUserStore();
        if (userStore.role === 'admin' || userStore.role === 'organizer') {
          return true
        } else {
          return { name: 'home' }
        }
      },
    },
    {
      path: "/form-event-link",
      name: "formeventlink",
      component: () => import("../views/Form/FormEventLinkView.vue"),
      beforeEnter: (to, from) => {
        const userStore = useUserStore();
        if (userStore.role === 'admin' || userStore.role === 'organizer') {
          return true
        } else {
          return { name: 'home' }
        }
      },
    },
    {
      path: "/form-event-creation",
      name: "formeventcreation",
      component: () => import("../views/Form/FormEventCreationView.vue"),
      beforeEnter: (to, from) => {
        const userStore = useUserStore();
        if (userStore.role === 'admin' || userStore.role === 'organizer') {
          return true
        } else {
          return { name: 'home' }
        }
      },
    },
    {
      path: "/form-event-info/:eventId",
      name: "formeventinfo",
      component: () => import("../views/Form/FormEventInfoModificationView.vue"),
      meta: {
        showNavbar: true,
      },
      beforeEnter: (to, from) => {
        const userStore = useUserStore();
        if (!userStore.eventList.find((event) => event.eventData.eventCode === to.params.eventId)) {
          return { name: 'home' }
        } else {
          return true
        }
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("../views/User/LogInView.vue"),
      beforeEnter: async (to, from) => {
        const res = await validTokenAfterError(true)
        if (res) {
          return { name: 'home' }
        } else {
          return true
        }
      },
    },
    {
      path: "/organisator-form",
      name: "organisatorform",
      meta: {
        showGeneralMenu: true,
      },
      component: () => import("../views/OrganisatorForm.vue"),
      beforeEnter: async (to, from) => {
        const res = await validTokenAfterError(true)
        if (res) {
          return true
        } else {
          return { name: 'home' }
        }
      },
    },
    {
      path: "/profil",
      name: "profil",
      meta: {
        showGeneralMenu: true,
      },
      component: () => import("../views/User/ProfilView.vue"),
      beforeEnter: async (to, from) => {
        const res = await validTokenAfterError(true)
        if (res) {
          return true
        } else {
          return { name: 'login' }
        }
      },
    },
    {
      path: "/qrcode/:eventId",
      name: "qrcode",
      meta: {
        showNavbar: true,
        showGeneralMenu: true,
      },
      beforeEnter: async (to, from) => {
        const res = await validTokenAfterError(true)
        if (res) {
          const userStore = useUserStore();
          if (!userStore.eventList.find((event) => event.eventData.eventCode === to.params.eventId)) {
            return { name: 'home' }
          } else {
            return true
          }
        } else {
          return { name: 'login' }
        }
      },
      component: () => import("../views/QRCodeView.vue"),
    },
    {
      path: "/report",
      name: "report",
      meta: {
        showGeneralMenu: true,
      },
      component: () => import("../views/ReportView.vue"),
    },
    {
      path: "/reset-password",
      name: "resetpassword",
      meta: {
        showGeneralMenu: true,
      },
      component: () => import("../views/User/ResetPasswordView.vue"),
    },
    {
      path: "/take-selfie",
      name: "takeselfie",
      meta: {
        showGeneralMenu: true,
      },
      component: () => import("../views/User/TakeSelfieView.vue"),
      beforeEnter: async (to, from) => {
        const res = await validTokenAfterError(true)
        if (res) {
          return true
        } else {
          return { name: 'home' }
        }
      },
    },
    {
      path: "/signin",
      name: "signin",
      component: () => import("../views/User/SignInView.vue"),
      beforeEnter: async (to, from) => {
        const res = await validTokenAfterError(true)
        if (res) {
          return { name: 'home' }
        } else {
          return true
        }
      },
    },
    {
      path: "/update-password",
      name: "updatepassword",
      component: () => import("../views/User/UpdatePasswordView.vue"),
      beforeEnter: async (to, from) => {
        const res = await validTokenAfterError(true)
        if (res) {
          return true
        } else {
          return { name: 'login' }
        }
      },
    },
    {
      path: "/update-name",
      name: "updatename",
      component: () => import("../views/User/UpdateNameView.vue"),
      beforeEnter: async (to, from) => {
        const res = await validTokenAfterError(true)
        if (res) {
          return true
        } else {
          return { name: 'login' }
        }
      },
    },
    {
      path: "/update-mail",
      name: "updatemail",
      component: () => import("../views/User/UpdateMailView.vue"),
      beforeEnter: async (to, from) => {
        const res = await validTokenAfterError(true)
        if (res) {
          return true
        } else {
          return { name: 'login' }
        }
      },
    },
    {
      path: "/update-ia",
      name: "updateia",
      component: () => import("../views/User/UpdateInfoIAView.vue"),
      beforeEnter: async (to, from) => {
        const res = await validTokenAfterError(true)
        if (res) {
          return true
        } else {
          return { name: 'login' }
        }
      },
    },
    {
      path: "/user-administration",
      name: "useradministration",
      meta: {
        showGeneralMenu: true,
      },
      component: () => import("../views/UserAdministrationView.vue"),
      beforeEnter: async (to, from) => {
        if (await TokenAdminVerification()) {
          return true
        } else {
          return { name: 'login' }
        }
      },
    },
    {
      path: "/user-page/:userId/event/:eventId",
      name: "userpage",
      meta: {
        showNavbar: true,
      },
      component: () => import("../views/UserPageView.vue"),
      beforeEnter: async (to, from) => {
        const eventId = to.params.eventId;
        const userId = to.params.userId;
        const res = await validTokenAfterError(true)
        if (res && eventId && userId) {
          const userStore = useUserStore();
          if (!userStore.eventList.find((event) => event.eventData.eventCode === to.params.eventId)) {
            return { name: 'home' }
          } else {
            return true
          }
        } else {
          return { name: 'login' }
        }
      },
    },
    {
      path: "/waiting-analysis",
      name: "waiting",
      component: () => import("../views/User/WaitingAnalysisView.vue"),
    },
    {
      path: '/:pathMatch(.*)*',
      meta: {
        showGeneralMenu: true,
      },
      component: () => import("../views/404View.vue"),
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (!Capacitor.isNativePlatform()) {
    next();
    return;
  }
  const isCameraView = to.name === 'camera';

  if (isCameraView) {
    ScreenOrientation.unlock();
  } else {
    ScreenOrientation.lock({
      orientation: 'portrait',
    });
  }
  next();
});


export default router;
