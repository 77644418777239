import { defineStore } from "pinia";
import { serverUrls } from "../api/constants";
import { callAPI, createMediaInCollection } from "../api/httpservice";
import { Filesystem, Directory } from "@capacitor/filesystem";
import { Network } from "@capacitor/network";
import { Buffer } from "buffer";
import { useUserStore } from "./userStore";
export const useEventStore = defineStore({
  id: "eventStore",
  state: () => ({
    eventId: "",
    eventUserRole: "",
    eventName: "",
    eventIsOpen: false,
    eventDescription: "",
    eventPlace: "",
    eventBackgroundUrl: "",
    eventDateStart: "",
    eventDateEnd: "",
    eventLink: "",
    eventCompetitionLink: "",
    eventOrgaLink: "",
    eventOrgaName: "",
    eventUserList: "",
    imageCount: 0,
    videoCount: 0,
    usersCount: 0,
    page: 0,
    mediasToUpload: [],
    uploadingFiles: [],
    isUploading: false,
    newDataToLoad: false,
    captureStarted: false,
    zoomCollectionOpen: false
  }),
  getters: {},
  actions: {
    async loadEventData(eventId) {
      const data = await useUserStore().eventList.find(
        (event) => event.eventData.eventCode === eventId
      );
      this.eventId = data.eventData.eventCode;
      this.eventName = data.eventData.name;
      this.eventIsOpen = data.eventData.ongoing;
      this.eventDescription = data.eventData.description;
      this.eventPlace = data.eventData.place;
      this.eventBackgroundUrl = data.eventData.coverUrl;
      this.eventDateStart = data.eventData.dateStart;
      this.eventDateEnd = data.eventData.dateEnd;
      this.eventOrgaLink = data.eventData.orgaLink;
      this.eventCompetitionLink = data.eventData.competitionLink;
      this.eventLink = data.eventData.organizerId;
      this.eventOrgaName = data.eventData.organizerName;
      this.eventUserList = data.eventData.usersList;
      this.imageCount = data.eventData.imageCount;
      this.videoCount = data.eventData.videoCount;
      this.usersCount = data.eventData.usersCount;
      this.eventUserRole = data.role;
    },
    async toggleFavorite(mediaId) {
      return await callAPI("POST", serverUrls.mediaUrl + "/toggle-favorite", {
        mediaId,
      })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    // CHECK IF USER IS CONNECTED TO INTERNET
    async logCurrentNetworkStatus() {
      const status = await Network.getStatus();
      return status.connected;
    },
    handleProgress(progress, tmpId) {
      this.uploadingFiles.forEach((file) => {
        if (file.tmpId === tmpId) {
          file.progress = progress;
        }
      });
    },
    // METHOD THAT CHECK IF THERE IS FILES TO UPLOAD THEN UPLOAD THEM
    async uploadFilesInCollection() {
      if (!this.isUploading) {
        this.isUploading = true;
        const readOptions = {
          path: "upload",
          directory: Directory.Data,
        };
        const filesList = await Filesystem.readdir(readOptions);
        if (!filesList.files.length) {
          this.isUploading = false;
          return;
        } else {
          this.mediasToUpload = filesList;
          if (this.mediasToUpload.files && this.mediasToUpload.files.length) {
            for (const file of this.mediasToUpload.files) {
              const connected = await this.logCurrentNetworkStatus();
              if (connected) {
                this.readFileData(file).then(async (fileData) => {
                  let fileType;
                  let blob;
                  const fileExtension = file.name
                    .split(".")
                    .pop()
                    .toLowerCase();
                  if (fileExtension === "jpg" || fileExtension === "jpeg") {
                    fileType = "image/jpg";
                    blob = this.b64toBlob(fileData, "image/jpg");
                  } else if (fileExtension === "mp4") {
                    blob = this.b64toBlob(fileData, "video/mp4");
                    fileType = "video/mp4";
                  } else {
                    console.warn(
                      `Unsupported file extension: ${fileExtension}`
                    );
                    return;
                  }
                  const inputFileObject = new File([blob], file.name, {
                    lastModified: file.mtime,
                    type: fileType,
                  });

                  let tmpId = "id" + new Date().getTime();
                  this.uploadingFiles.push({
                    progress: 0,
                    file: inputFileObject,
                    tmpId: tmpId,
                  });
                  console.log(inputFileObject);
                  createMediaInCollection(
                    inputFileObject,
                    this.eventId,
                    this.handleProgress,
                    tmpId
                  )
                    .then((res) => {
                      const deleteOptions = {
                        path: `${"upload/" + inputFileObject.name}`,
                        directory: Directory.Data,
                      };
                      Filesystem.deleteFile(deleteOptions);

                      if (res.status === 404) {
                        this.uploadingFiles.forEach((file) => {
                          if (file.tmpId === tmpId) {
                            file.error =
                              "Une erreur est survenue. Veuillez réesayer ultérieurement";
                          }
                        });

                        console.error(
                          "Une erreur est survenue. Veuillez réesayer ultérieurement",
                          error
                        );
                      } else {
                        let id = null;
                        this.uploadingFiles.forEach((file, index) => {
                          if (file.tmpId === tmpId) {
                            id = index;
                          }
                        });
                        if (id !== null) {
                          this.uploadingFiles.splice(id, 1);
                        }
                        const mediaId = this.mediasToUpload.files.findIndex(
                          (mediaFile) => mediaFile.name === file.name
                        );
                        if (mediaId !== -1) {
                          this.mediasToUpload.files.splice(mediaId, 1);
                          if (!this.mediasToUpload.files.length) {
                            this.isUploading = false;
                            this.newDataToLoad = true;
                          }
                        }
                      }
                    })
                    .catch((error) => {
                      this.isUploading = false;
                      console.error("Error reading file data:", error);
                    });
                });
              }
            }
          }
        }
      }
    },
    async readFileData(file) {
      try {
        const fileData = await Filesystem.readFile({
          path: `${"upload/" + file.name}`,
          directory: Directory.Data,
        });
        return fileData.data;
      } catch (error) {
        console.error("Error reading file data:", error);
        throw error;
      }
    },
    b64toBlob(base64String, contentType) {
      contentType = contentType || "";
      const sliceSize = 1024;
      const byteCharacters = Buffer.from(base64String, "base64").toString(
        "latin1"
      );
      const bytesLength = byteCharacters.length;
      const slicesCount = Math.ceil(bytesLength / sliceSize);
      const byteArrays = new Array(slicesCount);

      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);

        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      return new Blob(byteArrays, { type: contentType });
    },
    // MEDIA METHODS
    async getMedia(mediaId) {
      return await callAPI(
        "GET",
        serverUrls.mediaUrl + "/media?fileId=" + mediaId,
        null
      )
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async deleteMedia(mediaId) {
      return await callAPI("DELETE", serverUrls.mediaUrl + "/media", {
        mediaId,
      }).catch((err) => {
        console.error(err);
        throw new Error(err);
      });
    },
    async getAllMedia(
      eventId,
      withUser,
      byUser,
      favorites,
      photos,
      videos,
      page
    ) {
      let apiUrl = serverUrls.mediaUrl + "/media/all-media?eventId=" + eventId;

if (withUser) {
    apiUrl += "&withUser=" + withUser;
}
if (byUser) {
  apiUrl += "&byUser=" + byUser;
}
apiUrl +=
    "&favorites=" + favorites +
    "&photos=" + photos +
    "&videos=" + videos +
    "&page=" + page;

return await callAPI("GET", apiUrl, null)
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async getMediaFromUser( eventId, userId) {
      return await callAPI(
        "GET",
        serverUrls.mediaUrl +
          "/event/data-from-user?eventId=" +
          eventId +
          "&userId=" +
          userId,
        null
      )
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async askZip(eventId) {
      return await callAPI(
        "POST",
        serverUrls.mediaUrl + "/media/ask-zip?eventId=" + eventId,
        null
      )
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    // EVENT METHODS
    async getEvent(eventId) {
      return await callAPI(
        "GET",
        serverUrls.mediaUrl + "/event?eventId=" + eventId,
        null
      )
        .then((response) => {
          this.eventId = response.eventCode;
          this.eventName = response.name;
          this.eventIsOpen = response.ongoing;
          this.eventDescription = response.description;
          this.eventPlace = response.place;
          this.eventBackgroundUrl = response.coverUrl;
          this.eventDateStart = response.dateStart;
          this.eventDateEnd = response.dateEnd;
          this.eventOrgaLink = response.orgaLink;
          this.eventCompetitionLink = response.competitionLink;
          this.eventOrgaName = response.organizerName;
          this.eventLink = response.organizerId;
          this.eventUserList = response.usersList;
          this.imageCount = response.imageCount;
          this.videoCount = response.videoCount;
          this.usersCount = response.usersCount;
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async deleteEvent(eventId) {
      return await callAPI("DELETE", serverUrls.mediaUrl + "/event", {
        eventId,
      })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
    async removeEventData() {
      this.eventId = "";
      this.eventName = "";
      this.eventIsOpen = false;
      this.eventDescription = "";
      this.eventPlace = "";
      this.eventBackgroundUrl = "";
      this.eventDateStart = "";
      this.eventDateEnd = "";
      this.eventLink = "";
      this.eventOrgaLink = "";
      this.eventCompetitionLink = "";
      this.eventOrgaName = "";
      this.eventUserList = "";
      this.imageCount = 0;
      this.videoCount = 0;
      this.usersCount = 0;
      this.eventUserRole = "";
    },
    async getBase64ImageFromUrl(mediaId) {
      return await callAPI(
        "GET",
        serverUrls.mediaUrl + "/media/base64-image?mediaId=" + mediaId,
        null
      )
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
          throw new Error(err);
        });
    },
  },
});
